@font-face {
  font-family: GothamPro;
  src: url("../../styles/GothamPro-Medium.ttf");
}

.chosenTime{
  border-radius:100px;
  box-shadow: 0px 8px 12px rgba(20,20,20,0.2);
  padding:10px;
  font-family: 'Open Sans',sans-serif;
  font-size: 0.9rem;
  width:240px;
  text-align: center;
  margin-left:12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border:0px;
  color:white;
  font-weight: bold;
  background:#215b63;
  display: inline-block;
  vertical-align: bottom;
  border:1px solid white;
  margin-bottom: 5px;
}

.cardHeaderIcon{
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-top: -5px;
  height:28px;
}

.locationsForm{
  flex:1;
  display: flex;
  padding:2vw;
  padding-top: 1vh;
  color:#484848;
  flex-direction: row;
}

.locationsEntryColumn{
  flex:1;
  overflow-y: auto;
  border-left: 1px solid #989898;
}

.locationsListColumn{
  flex:1;
  overflow-y: auto;
}


.timeslotsContent{
  padding:20px;
  text-align: left;
  font-family: "Comfortaa", cursive;
}

.dateTimeContainer{
  display: inline-block;
  vertical-align: middle;
  margin-top: 20px;
}

.searchContainer{
  display: inline-block;
  vertical-align: middle;
  margin-top: -20px;
  margin-left: 20px;
}

.filesList{
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right:4vw;
}

.removeFileButton{
  width:14px;
  height:14px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.removeFileButton:hover{
  cursor:pointer;
}


.cardHeader{
  background:#20b0ab;
  padding:10px;
  padding-left: 32px;
  color:white;
  font-size: 1.2rem;
  text-align: left;
  font-family: GothamPro, sans-serif;
  display: flex;
  flex-direction: row;
}

.cardColumnContainer{
  display: flex;
  flex-direction: row;
  width:100%;
}

.cardColumn{
  flex:1;
  padding:10px;
}

.homeContentContainerMain{
  position: absolute;
  top: 0vh;
  width:calc(100%);
  height:calc(100%);
  display: flex;
  flex-direction: row;
}

.homeDashboardContentMain{
  height:100%;
  flex:5;
  display:flex;
  flex-direction: column;
  overflow-y: auto;
}

.homeContentRow{
  flex:1;
  height:16vh;
  display: flex;
  margin-bottom: 4vh;
  background:blue;
}

.homeContentCardMiniMain{
  height:100%;
  flex:1;
  background: #fff;
  border-radius:0px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:16px;
  margin-left: 1vw;
  margin-right: 1vw;
  position: relative;
}

#homeContentCardMiniHeader{
  color:white;
  text-shadow: 2px 2px 3px 3px #3300BB;
  padding-bottom: 3vh;
}

.homeContentCardMiniHeader{
  color:#8980ad;
  font-family: "Comfortaa", cursive;
  font-size: 1rem;
  padding:0.5vw;
  padding-top:0.5vh;
  text-align: left;
}

.homeRecentRevenue{
  color:white;
  font-weight: 600;
  font-family: "Comfortaa", cursive;
  font-size: 2.2rem;
  padding-left:0.5vw;
  padding-right: 0.5vw;
  text-align: center;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  padding-top: 1vh;
}

.bookingsCard{
  margin-top: 6vh;
  display: flex;
  flex:4;
  flex-direction:column;
  background: #fff;
  border-radius:24px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:16px;
  margin-left: 1vw;
  margin-right: 1vw;
  overflow-y: auto;
}

#homeRecentBookingsTable{
  padding-top: 3vh;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.homeRecentBookingsTableHeader{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top:1vh;
  padding-bottom:1vh;
  background:#2e51ff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.homeRecentBookingsRowEven{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
}
.homeRecentBookingsRowOdd{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  background:#f5f6ff;
}

.homeResultHeaderItem{
  color:white;
  font-family: "Comfortaa", cursive;
  font-size: 0.9rem;
  flex:1;
}

.homeRecentBookingsRowOddItem{
  color:#8980ad;
  font-family: "Comfortaa", cursive;
  font-size: 0.9rem;
  flex:1;
}

.homeRecentBookingsRowEvenItem{
  color:#8980ad;
  font-family: "Comfortaa", cursive;
  font-size: 0.9rem;
  flex:1;
}

#home-bookings-header{
  flex-direction: row;
}

.homeRecentBookingsTitleContainer{
  text-align: left;
  display: flex;
}

.homeRecentBookingsSearchContainer{
  text-align: right;
  flex:1;
  padding:10px;
  display: flex;
}

.homeCardHeaderPlain{
  padding:8px;
  padding-left: 12px;
  text-align: left;
}

.homeCardHeaderIcon{
  display:inline-block;
  vertical-align: middle;
  width:26px;
}

.homeCardHeaderText{
  display:inline-block;
  vertical-align: middle;
  color:#343434;
  font-family: "Comfortaa", cursive;
  font-size: 1rem;
  margin-left: 10px;
}

.dashboardWidgetIcon{
  display: inline-block;
  vertical-align: middle;
  margin-left: 24px;
  width:30px;
  height:30px;
  margin-top: -2px;
}

.dashboardWidgetText{
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.dashboardWidgetHeader{
  color:white;
  font-size: 1rem;
  font-family: "Comfortaa", cursive;
  text-align: left;
}

.dashboardWidgetSubheader{
  color:white;
  opacity:0.7;
  font-family: "Comfortaa", cursive;
  font-size: 0.7rem;
  text-align: left;
}

.dashboardCardHeader{
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-top: 4px;
  padding-bottom:8px;
  background:#2e51ff;
  align-items: flex-start;
  text-align: left;
}

.dashboardCardRow{
  margin-top: 5px;
}

.dashboardCardRow2{
  margin-top: 5px;
  padding-top: calc(1vh - 2px);
  padding-bottom: calc(1vh - 2px);
}

.dashboardCardContent{
  flex-direction: column;
  flex:1;
}

.dashboardCardFooter{
  padding-top:10px;
  padding-bottom: 10px;
  border-top:1px solid #BCBCBC;
}

.dashboardCardFooterText{
  font-family: "Comfortaa", cursive;
  color:#787878;
  font-size: 0.9rem;
}

.dashboardCardPageInput{
  border:1px solid #787878;
  margin-left: 10px;
  margin-right: 10px;
  width:40px;
  text-align: center;
  padding:5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 100px;
  color:#787878
}

.dashboardCardPageButton{
  width:20px;
  height:20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboardCardPageButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.noOrdersContainer{
  margin-top: 16vh;
  color:#ABABAB;
  font-size: 1.1rem;
  font-family: "Nunito", sans-serif;
}

.noOrdersGraphic{
  width:10vw;
  margin-bottom: 6vh;
}

.ordersRecentBookingsTable{
  margin-top: 0vh;
  padding:0px;
  border:0px solid #e5e6ef;
  padding-top: 0px;
  border-radius:16px;
  width:100%;
}


.ordersRecentBookingsTableHeader{
  width:100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-top:0.8vh;
  font-size:0.9rem;
  padding-bottom:0.8vh;
  background:#BDBDFF;
  margin:0px;
}

.productsRowEven{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1vh;
  margin:0px;
}
.productsRowOdd{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1vh;
  background:#f5f6ff;
  margin:0px;
}

.productsRowEven:hover{
  cursor: pointer;
}
.productsRowOdd:hover{
  cursor: pointer;
}

.productsResultHeaderItem{
  color:white;
  font-family: "Comfortaa", cursive;
  font-size: 0.9rem;
  flex:1;
  margin:0px;
}

.ordersRecentBookingsRowOddItem{
  color:#8980ad;
  font-family: "Comfortaa", cursive;
  font-size: 0.9rem;
  flex:1;
  margin:0px;
}

.ordersRecentBookingsRowEvenItem{
  color:#8980ad;
  font-family: "Comfortaa", cursive;
  font-size: 0.9rem;
  flex:1;
  padding:0px;
  margin:0px;
  display: inline-block;
  vertical-align: middle;
}

.ordersNewProductWindowContainer{
  position: fixed;
  top:0px;
  left:0px;
  z-index:200;
  width:100%;
  height:100%;
  background:#AEAEC1BB;
  backdrop-filter: blur(3px);
}

@keyframes ordersPopup {
  from {opacity:0;}
  to {opacity:1;}
}

.ordersNewProductWindow{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:70%;
  height:85%;
  background:white;
  border-radius:14px;
  border:1px solid #3814ba;
  box-shadow: 0px 8px 12px #7B78CC;
  animation-name: ordersPopup;
  animation-duration: 0.9s;
  font-family: "Comfortaa", cursive;
  font-size: 1rem;
  color:#484848;
  display: inline-block;
  text-align: left;
  overflow-y: auto;
}

.ordersNewProductHeader{
  padding:2vh;
  color:#989898;
}

.ordersFormNote{
  margin-top: 3vh;
  padding-right: 10vw;
  color:#989898;
}

.ordersForm{
  padding:2vw;
  padding-top: 1vh;
  color:#484848;
}

.ordersRecentBookingsTitleIcon{
  display: inline-block;
  vertical-align: middle;
  width:60px;
  margin-right: 20px;
  margin-left: 10px;
}
.ordersRecentBookingsTitleText{
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
}

.ordersXButton{
  position: absolute;
  top:20px;
  right:20px;
  width:16px;
}

@keyframes ordersSpinner {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.ordersXButton:hover{
  cursor:pointer;
  animation-name: ordersSpinner;
  animation-duration: 0.8s;
}


.superAdminPlaceholderContainer{
  display: inline-block;
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  text-align: left;
}

.superAdminPlaceholderTitle{
  color:#ABABAB;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
}

.superAdminPlaceholder{
  background:white;
  border-radius:12px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:30px;
  font-family: "Comfortaa", cursive;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  font-size: 0.9rem;
  width:13.5vw;
  margin-top: 5px;
  border:0px;
  color:#787878;
  background:rgba(10,0,210,0.1);
}

.formButtonContainer{
  text-align: right;
  margin-right: 3.5vw;
  margin-top: 3vh;
}

.ordersFormColoursTitle{
  color:#ABABAB;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  vertical-align: middle;
}

.ordersFormColour{
  background: #627EFB22;
  padding: 14px;
  border-radius: 10px;
  margin-top: 1.6vh;
  margin-right: 50px;
  color:#989898;
  font-size: 0.9rem;
}

.removeIcon{
  display: inline-block;
  vertical-align: middle;
  width:18px;
  margin-left: 12px;
  margin-top: -4px;
}

.removeIcon:hover{
  cursor:pointer;
  opacity:0.6;
}
